.cron[data-v-d4138134] .el-tabs--border-card > .el-tabs__content {
  padding: 15px !important;
  background: #ffffff;
}
.el-radio-group[data-v-d4138134] {
  line-height: 20px !important;
}
.rule-tem-list[data-v-d4138134] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.rule-tem-list li[data-v-d4138134] {
  list-style: none;
  width: 100px;
  height: 100px;
  border: 1px solid #666666;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 20px;
  cursor: pointer;
}
.rule-tem-list li.active[data-v-d4138134] {
  border-color: #409EFF;
  color: #409EFF;
}